import 'react-calendar/dist/Calendar.css';
import 'react-datepicker/dist/react-datepicker.css';

import {
  faCaretDown,
  faCaretUp,
  faEdit,
  faPen,
  faTimesCircle,
  faTrash,
  faTrashRestore,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mongoose from 'mongoose';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Overlay from 'react-bootstrap/Overlay';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import Calendar from 'react-calendar';
import DatePicker from 'react-datepicker';
import { Redirect } from 'react-router-dom';

import * as ErrorMessagingConstants from '../../constants/error-messaging';
import * as RegexPatternConstants from '../../constants/regex-patterns';
import {
  isEmptyObject,
  isValidByRegexPattern,
  truncate,
  whichCharactersAreInvalidByRegexPattern,
} from '../../helpers/helperFunctions';
import AlertDismissible from '../Alert/AlertDismissible';

const validInputNames = ['email', 'permissions', 'firstName', 'lastName'];
const modalActionButtonColorMap = {
  edit: 'primary',
  archive: 'danger',
  delete: 'danger',
  instructions: 'dark',
};

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      userId: '',
      modalTitle: '',
      modalActionButton: '',
      firstName: '',
      lastName: '',
      email: '',
      permissions: '',
      error_firstName: '',
      error_larstName: '',
      error_email: '',
      error_showName: '',
      propsFirstName: '',
      propsLastName: '',
      propsEmail: '',
      propsPermissions: '',
      modalAction: '',
      focused_firstName: false,
      focused_lastName: false,
      focused_email: false,
      focused_showName: false,
      validated: false,
      show: true,
      sortedBy: 'displayNameDown',
      showArchivedUsers: false,
      isShowActive: false,
      startDate: 0,
      endDate: 0,
      showName: '',
      focusedDay: '',
      focusedDayState: 'none',
      currentProposedDate: '',
      proposedShowDates: [],
      savedShowDates: [],
      displayShowTimes: [],
      currentShowTitle: '',
      showTooltipForId: '',
      invalidShowName: '',
      expirationDate: '',
      propsExpirationDate: '',
    };
    this.openModalWithUserInfo = this.openModalWithUserInfo.bind(this);
    this.openModalForArchiveUser = this.openModalForArchiveUser.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onHandleModalSubmit = this.onHandleModalSubmit.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeShowName = this.onChangeShowName.bind(this);
    this.removeShowDate = this.removeShowDate.bind(this);
    this.onCalendarChange = this.onCalendarChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.reroute = this.reroute.bind(this);
    this.openModalForInstructions = this.openModalForInstructions.bind(this);
    this.archiveUser = this.archiveUser.bind(this);
    this.proposedShowDatesFilterForDuplicates = this.proposedShowDatesFilterForDuplicates.bind(
      this
    );
    this.restoreUser = this.restoreUser.bind(this);
    this.setInputEmptyValidationMessage = this.setInputEmptyValidationMessage.bind(
      this
    );
    this.isValidInputName = this.isValidInputName.bind(this);
    this.normalizeDatesToString = this.normalizeDatesToString.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.setInputFocus = this.setInputFocus.bind(this);
    this.onSortBy = this.onSortBy.bind(this);
    this.saveShow = this.saveShow.bind(this);
    this.sortDates = this.sortDates.bind(this);
    this.sortBy = this.sortBy.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.isFormValidToSubmit = this.isFormValidToSubmit.bind(this);
    this.clearInputValidationMessage = this.clearInputValidationMessage.bind(
      this
    );

    this.target = React.createRef(null);
  }

  handleClose() {
    this.setState({
      showModal: false,
      userId: '',
      modalTitle: '',
      firstName: '',
      lastName: '',
      email: '',
      permissions: '',
      error_firstName: '',
      error_larstName: '',
      error_email: '',
      propsFirstName: '',
      propsLastName: '',
      propsEmail: '',
      propsPermissions: '',
      modalAction: '',
      focused_firstName: false,
      focused_lastName: false,
      focused_email: false,
      focused_showName: false,
      validated: false,
      isShowActive: false,
      proposedShowDates: [],
      showName: '',
      expirationDate: '',
    });
  }

  removeShowDate(sd, removeFrom = 'proposed', showId = '') {
    if (removeFrom === 'proposed') {
      this.setState({
        proposedShowDates: this.state.proposedShowDates
          .sort(this.sortDates())
          .filter((pd) => {
            return !this.compareDatesNormalized(pd.date, sd.date);
          }),
      });
    } else if (removeFrom === 'saved') {
      var shows = [];
      this.state.savedShowDates.forEach((savedShow) => {
        if (savedShow._id === showId) {
          var dates = savedShow.dates.filter((d) => {
            if (this.compareDatesNormalized(d.date, sd.date)) {
              return false;
            }
            return true;
          });
          shows.push({
            _id: savedShow._id,
            title: savedShow.title,
            dates: dates,
          });
        } else {
          shows.push(savedShow);
        }
      });

      this.setState(
        {
          savedShowDates: shows,
        },
        () => {
          this.setState({
            displayShowTimes: this.state.savedShowDates,
          });
          this.props.updateSchedule(this.state.savedShowDates);
        }
      );
    }
  }

  handleTitleChange(id) {
    var update = false;
    this.state.savedShowDates.forEach((show) => {
      if (
        show._id === id &&
        show.title !== this.state.currentShowTitle.trim()
      ) {
        show.title = this.state.currentShowTitle;
        update = true;
      }
    });
    if (update) {
      this.props.updateSchedule(this.state.savedShowDates);
    }
    this.setState({ currentShowTitle: '' });
  }

  proposedShowDatesFilterForDuplicates() {
    var proposedDates = this.state.proposedShowDates;
    var returnDates = [];
    var isDateNew = true;
    proposedDates.forEach((pd) => {
      if (this.compareDatesNormalized(pd.date, this.state.focusedDay)) {
        returnDates.push({
          date: this.state.focusedDay,
          dayState: this.state.focusedDayState,
        });
        isDateNew = false;
      } else {
        returnDates.push({ date: pd.date, dayState: pd.dayState });
      }
    }, isDateNew);
    if (isDateNew) {
      returnDates = returnDates.concat({
        date: this.state.focusedDay,
        dayState: this.state.focusedDayState,
      });
    }
    return returnDates.sort(this.sortDates());
  }

  saveShow() {
    if (this.state.isShowActive) {
      if (this.state.focusedDayState !== 'none') {
        this.setState(
          {
            isShowActive: false,
            savedShowDates: this.state.savedShowDates.concat({
              _id: new mongoose.Types.ObjectId(),
              title: this.state.showName,
              dates: this.proposedShowDatesFilterForDuplicates(),
            }),
            proposedShowDates: [],
            focusedDay: '',
            currentProposedDate: '',
            focusedDayState: 'none',
          },
          () => {
            this.props.updateSchedule(this.state.savedShowDates);
          }
        );
      } else {
        this.setState({
          isShowActive: false,
          savedShowDates: this.state.savedShowDates.concat({
            _id: new mongoose.Types.ObjectId(),
            title: this.state.showName,
            dates: this.state.proposedShowDates.sort(this.sortDates()),
          }),
          proposedShowDates: [],
          focusedDay: '',
          currentProposedDate: '',
          focusedDayState: 'none',
        });
      }
    } else {
      this.setState({ isShowActive: true, displayShowTimes: [] });
    }
  }

  normalizeDatesToString(dateString) {
    var date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  openModalWithUserInfo(userToEdit) {
    this.state.tickets.forEach((t) => {
      if (t.user._id === userToEdit._id) {
        this.setState({
          expirationDate: t.expiryDate,
          propsExpirationDate: t.expiryDate,
        });
      }
    });
    this.setState({
      showModal: true,
      modalAction: 'edit',
      modalTitle: `Edit ${userToEdit.displayName}'s Profile`,
      modalActionButton: 'Update',
      firstName: userToEdit.firstName,
      propsFirstName: userToEdit.firstName,
      lastName: userToEdit.lastName,
      propsLastName: userToEdit.lastName,
      email:
        userToEdit.profileType === 'google'
          ? 'Google user, email cannot be updated'
          : userToEdit.email
          ? userToEdit.email
          : '',
      propsEmail:
        userToEdit.profileType === 'google'
          ? 'Google user, email cannot be updated'
          : userToEdit.email
          ? userToEdit.email
          : '',
      permissions: userToEdit.permissions,
      propsPermissions: userToEdit.permissions,
      userId: userToEdit._id,
    });
  }

  openModalForArchiveUser(userInfo) {
    this.setState({
      showModal: true,
      modalAction: 'archive',
      modalTitle: 'Archive User',
      modalActionButton: 'Archive',
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      userId: userInfo._id,
    });
  }

  openModalForDeleteUser(userInfo) {
    this.setState({
      showModal: true,
      modalAction: 'delete',
      modalTitle: 'Delete User',
      modalActionButton: 'Delete',
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      userId: userInfo._id,
    });
  }

  openModalForInstructions() {
    this.setState({
      showModal: true,
      modalAction: 'instructions',
      modalTitle: 'Schedule Instructions',
      modalActionButton: 'OK',
    });
  }

  archiveUser() {
    this.props.onUpdateUserFormSubmit(this.state.userId, {
      permissions: 'archived',
      password: '',
      profileType: 'ticket-only',
    });
    this.setState({ showModal: false });
  }

  deleteUser() {
    this.props.deleteUser(this.state.userId);
    this.setState({ showModal: false });
  }

  restoreUser(userToArchive) {
    this.props.onUpdateUserFormSubmit(userToArchive._id, {
      permissions: 'ticket-only',
    });
  }

  setInputEmptyValidationMessage(name) {
    if (
      name !== 'email' ||
      (name === 'email' && this.state.email !== this.state.propsEmail)
    ) {
      this.setState({
        [`error_${name}`]: ErrorMessagingConstants.NAME_ATTRIBUTE_TO_ERROR_MAP
          .EMPTY[name],
      });
    }
  }

  clearInputValidationMessage(name) {
    this.setState({
      [`error_${name}`]: '',
    });
  }

  setInputFocus(name) {
    let stateObj = {
      focused_firstName: name === 'firstName',
      focused_lastName: name === 'lastName',
      focused_email: name === 'email',
      focused_showName: name === 'showName',
    };
    this.setState(stateObj);
  }

  onCalendarChange(e) {
    var dayState = 'none';
    if (this.state.isShowActive) {
      if (!this.compareDatesNormalized(e, this.state.focusedDay)) {
        dayState = 'none';
      } else if (this.state.focusedDayState === 'evening') {
        dayState = 'matinee';
      } else if (this.state.focusedDayState === 'matinee') {
        dayState = 'both';
      } else if (
        this.state.focusedDayState === 'none' &&
        this.compareDatesNormalized(e, this.state.focusedDay)
      ) {
        dayState = 'evening';
      }
      if (
        !this.compareDatesNormalized(e, this.state.focusedDay) &&
        this.state.focusedDayState !== 'none'
      ) {
        this.setState({
          focusedDayState: dayState,
          proposedShowDates: this.proposedShowDatesFilterForDuplicates(),
        });
      } else {
        this.setState({
          focusedDayState: dayState,
        });
      }
    } else {
      this.setState({
        displayShowTimes: this.state.savedShowDates.filter((sd) => {
          var isShowValidForToday = false;
          sd.dates.forEach((d) => {
            if (this.compareDatesNormalized(e, d.date)) {
              isShowValidForToday = true;
            }
          });
          return isShowValidForToday;
        }),
      });
    }
    var normalizedDate = new Date(e);

    this.setState({
      focusedDay: e,
      currentProposedDate: `${
        normalizedDate.getMonth() + 1
      }/${normalizedDate.getDate()}/${normalizedDate.getFullYear()}`,
    });
  }

  validateInput(name, value) {
    if (
      !isValidByRegexPattern(
        value,
        RegexPatternConstants.NAME_ATTRIBUTE_TO_PATTERN_MAP[name]
      )
    ) {
      this.setState({
        [`error_${name}`]: ErrorMessagingConstants.NAME_ATTRIBUTE_TO_ERROR_MAP
          .INVALID[name],
      });
    }
  }

  isValidInputName(name) {
    return validInputNames.includes(name);
  }

  isFormValidToSubmit() {
    validInputNames.forEach((inputName) => {
      if (
        this.state[`error_${inputName}`] ||
        !this.state[`${inputName}`] ||
        inputName === 'email'
      ) {
        return false;
      }
    });
    return true;
  }

  onChange(e) {
    try {
      if (this.isValidInputName(e.target.name)) {
        if (!e.target.value) {
          this.setState({ validated: false });
          this.setInputEmptyValidationMessage(e.target.name);
        } else {
          this.setState({ validated: true });
          this.clearInputValidationMessage(e.target.name);
        }

        if (e.target.name && e.target.value) {
          this.validateInput(e.target.name, e.target.value);
        }

        this.setState({ [e.target.name]: e.target.value });
      }
    } catch (err) {
      console.error(err);
    }
  }

  onChangeShowName(e) {
    try {
      if (!e.target.value) {
        this.setState({
          canSaveShow: false,
          error_showName: 'Name is required',
        });
      } else if (
        !isValidByRegexPattern(
          e.target.value,
          RegexPatternConstants.PATTERN.TEXT_VALIDATION
        )
      ) {
        this.setState({
          canSaveShow: false,
          error_showName: 'Name can only contain letters',
        });
      } else {
        this.setState({
          canSaveShow: true,
          error_showName: '',
        });
      }

      this.setState({ [e.target.name]: e.target.value });
    } catch (err) {
      console.error(err);
    }
  }

  onSearch(e) {
    try {
      this.setState({
        users: this.props.users.filter((user) => {
          var isValidSearchResult = false;
          if (user.displayName !== undefined) {
            isValidSearchResult = user.displayName
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          }
          if (!isValidSearchResult && user.email !== undefined) {
            isValidSearchResult = user.email
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          }
          if (!isValidSearchResult && user.permissions !== undefined) {
            isValidSearchResult = user.permissions
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          }
          return isValidSearchResult;
        }),
      });
    } catch (err) {
      console.error(err);
    }
  }

  onFocus(e) {
    this.setInputFocus(e.target.name);
  }

  onBlur(e) {
    this.setState({ [`focused_${e.target.name}`]: false });
  }

  sortBy(category, reverse) {
    if (category === 'displayName') {
      return function (a, b) {
        if (a.permissions === 'archived' && b.permissions !== 'archived') {
          return 1;
        }
        if (a.permissions !== 'archived' && b.permissions === 'archived') {
          return -1;
        } else if (
          a[category].split(' ')[a[category].split(' ').length - 1] >
          b[category].split(' ')[b[category].split(' ').length - 1]
        ) {
          return reverse ? -1 : 1;
        } else if (
          a[category].split(' ')[a[category].split(' ').length - 1] <
          b[category].split(' ')[b[category].split(' ').length - 1]
        ) {
          return reverse ? 1 : -1;
        }
        return 0;
      };
    } else {
      return function (a, b) {
        if (a.permissions === 'archived' && b.permissions !== 'archived') {
          return 1;
        }
        if (a.permissions !== 'archived' && b.permissions === 'archived') {
          return -1;
        } else if (a[category] > b[category]) {
          return reverse ? -1 : 1;
        } else if (a[category] < b[category]) {
          return reverse ? 1 : -1;
        }
        return 0;
      };
    }
  }

  sortDates() {
    return function (a, b) {
      if (a.date > b.date) {
        return 1;
      } else if (a.date < b.date) {
        return -1;
      }
      return 0;
    };
  }

  onSortBy(category) {
    if (this.state.sortedBy === `${category}Down`) {
      this.setState({
        sortedBy: `${category}Up`,
        users: this.state.users.sort(this.sortBy(category, true)),
      });
    } else {
      this.setState({
        sortedBy: `${category}Down`,
        users: this.state.users.sort(this.sortBy(category, false)),
      });
    }
  }

  compareDatesNormalized(dateString1, dateString2) {
    var date1 = new Date(dateString1);
    var date2 = new Date(dateString2);
    return (
      `${date1.getMonth() + 1}/${date1.getDate()}/${date1.getFullYear()}` ===
      `${date2.getMonth() + 1}/${date2.getDate()}/${date2.getFullYear()}`
    );
  }

  onHandleModalSubmit(e) {
    if (this.state.modalAction === 'archive') {
      this.archiveUser();
    } else if (this.state.modalAction === 'delete') {
      this.deleteUser();
    } else {
      this.onSubmit(e);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.currentTarget.checkValidity() && this.isFormValidToSubmit()) {
      this.setState({ validated: true });
      var updateUserInfo = {};
      if (this.state.expirationDate !== this.state.propsExpirationDate) {
        updateUserInfo.expirationDate = this.state.expirationDate;
      }
      if (this.state.email !== this.state.propsEmail) {
        updateUserInfo.email = this.state.email;
      }
      if (this.state.firstName !== this.state.propsFirstName) {
        updateUserInfo.firstName = this.state.firstName;
        updateUserInfo.displayName =
          this.state.firstName + ' ' + this.state.lastName;
      }
      if (this.state.lastName !== this.state.propsLastName) {
        updateUserInfo.lastName = this.state.lastName;
      }
      if (this.state.permissions !== this.state.propsPermissions) {
        updateUserInfo.permissions = this.state.permissions;
        if (
          this.state.permissions === 'ticket-only' ||
          this.state.permissions === 'archived'
        ) {
          updateUserInfo.password = '';
          updateUserInfo.profileType = 'ticket-only';
        }
      }
      if (!isEmptyObject(updateUserInfo)) {
        this.props.onUpdateUserFormSubmit(this.state.userId, updateUserInfo);
      }
    } else {
      this.setState({ validated: false });
    }
    this.handleClose();
  }

  reroute(pathTo, pathFrom) {
    this.props.rerouteWithComponentLink(pathTo, pathFrom);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.users !== this.props.users) {
      this.setState({ users: this.props.users });
    }

    if (prevProps.shows !== this.props.shows) {
      this.setState({ savedShowDates: this.props.shows });
    }

    if (prevProps.tickets !== this.props.tickets) {
      this.setState({ tickets: this.props.tickets });
    }
  }

  componentDidMount() {
    if (this.props.users) {
      this.setState({
        users: this.props.users,
      });
    }

    if (this.props.tickets) {
      this.setState({
        tickets: this.props.tickets,
      });
    }

    if (this.props.shows) {
      this.setState({
        savedShowDates: this.props.shows,
      });
    }

    document.title = 'Dashboard';
  }

  render() {
    const { from } = this.props.from || { from: '/login' };
    let permissions = this.props.permissions || 'ticket-only';
    let errorMessagePassedOn = this.props.errorMessagePassedOn || '';
    let successMessagePassedOn = this.props.successMessagePassedOn || '';

    if (permissions === 'ticket-only') {
      return <Redirect to={from} />;
    }

    return (
      <>
        <Container className="main-container">
          {errorMessagePassedOn && (
            <AlertDismissible
              variant="danger"
              show={this.state.show}
              message={errorMessagePassedOn}
              clearAlertMessages={this.props.clearAlertMessages}
            />
          )}
          {this.props.successMessagePassedOn && (
            <AlertDismissible
              variant="success"
              show={this.state.show}
              message={successMessagePassedOn}
              clearAlertMessages={this.props.clearAlertMessages}
            />
          )}
          <Row className="dashboard-section">
            <Col
              lg={{ span: 8, order: 1 }}
              md={{ span: 12, order: 2 }}
              sm={{ span: 12, order: 2 }}
              xs={{ span: 12, order: 2 }}
            >
              <Calendar
                view="month"
                defaultView="month"
                maxDetail="month"
                minDetail="month"
                calendarType="US"
                tileContent={(date) => {
                  if (
                    this.compareDatesNormalized(
                      this.state.focusedDay,
                      date.date
                    )
                  ) {
                    var tempTodayElem = <></>;

                    var eveningTodayCount = 0;
                    var matineeTodayCount = 0;
                    var bothTodayCount = 0;
                    if (this.state.focusedDayState === 'evening') {
                      eveningTodayCount += 1;
                    } else if (this.state.focusedDayState === 'matinee') {
                      matineeTodayCount += 1;
                    } else if (this.state.focusedDayState === 'both') {
                      bothTodayCount += 1;
                    }
                    if (this.state.savedShowDates.length > 0) {
                      this.state.savedShowDates.forEach((sd) => {
                        sd.dates.forEach((d) => {
                          if (
                            d.date &&
                            this.compareDatesNormalized(d.date, date.date)
                          ) {
                            if (d.dayState === 'evening') {
                              eveningTodayCount += 1;
                            } else if (d.dayState === 'matinee') {
                              matineeTodayCount += 1;
                            } else if (d.dayState === 'both') {
                              bothTodayCount += 1;
                            }
                          }
                        });
                      });
                    }
                    tempTodayElem = (
                      <span className="last-col">
                        {(matineeTodayCount > 0 || bothTodayCount > 0) && (
                          <span className="mat-label">
                            M
                            {matineeTodayCount + bothTodayCount > 1
                              ? 'x' + (matineeTodayCount + bothTodayCount)
                              : ''}
                          </span>
                        )}
                        {(eveningTodayCount > 0 || bothTodayCount > 0) && (
                          <span className="eve-label">
                            E
                            {eveningTodayCount + bothTodayCount > 1
                              ? 'x' + (eveningTodayCount + bothTodayCount)
                              : ''}
                          </span>
                        )}
                      </span>
                    );
                    return tempTodayElem;
                  } else {
                    var tempElem = <></>;
                    var eveningCount = 0;
                    var matineeCount = 0;
                    var bothCount = 0;
                    if (this.state.proposedShowDates.length > 0) {
                      this.state.proposedShowDates.forEach((pd) => {
                        if (this.compareDatesNormalized(pd.date, date.date)) {
                          if (pd.dayState === 'evening') {
                            eveningCount += 1;
                          } else if (pd.dayState === 'matinee') {
                            matineeCount += 1;
                          } else if (pd.dayState === 'both') {
                            bothCount += 1;
                          }
                        }
                      });
                    }
                    if (this.state.savedShowDates.length > 0) {
                      this.state.savedShowDates.forEach((sd) => {
                        sd.dates.forEach((d) => {
                          if (
                            d.date &&
                            this.compareDatesNormalized(d.date, date.date)
                          ) {
                            if (d.dayState === 'evening') {
                              eveningCount += 1;
                            } else if (d.dayState === 'matinee') {
                              matineeCount += 1;
                            } else if (d.dayState === 'both') {
                              bothCount += 1;
                            }
                          }
                        });
                      });
                    }
                    tempElem = (
                      <span className="last-col">
                        {(matineeCount > 0 || bothCount > 0) && (
                          <span className="mat-label">
                            M
                            {matineeCount + bothCount > 1
                              ? 'x' + (matineeCount + bothCount)
                              : ''}
                          </span>
                        )}
                        {(eveningCount > 0 || bothCount > 0) && (
                          <span className="eve-label">
                            E
                            {eveningCount + bothCount > 1
                              ? 'x' + (eveningCount + bothCount)
                              : ''}
                          </span>
                        )}
                      </span>
                    );
                    return tempElem;
                  }
                }}
                tileClassName={(date) => {
                  // date will return every date visible on calendar and view will view type (eg. month)
                  if (
                    this.compareDatesNormalized(
                      this.state.focusedDay,
                      date.date
                    )
                  ) {
                    if (this.state.isShowActive) {
                      if (this.state.focusedDayState === 'evening') {
                        return 'evening-show-tile selected-show-tile';
                      } else if (this.state.focusedDayState === 'matinee') {
                        return 'matinee-show-tile selected-show-tile';
                      } else if (this.state.focusedDayState === 'both') {
                        return 'both-show-tile selected-show-tile';
                      } else {
                        return 'active-show-tile';
                      }
                    } else {
                      var tempClassToday = 'muted-calendar-tile';
                      if (this.state.savedShowDates.length > 0) {
                        this.state.savedShowDates.forEach((sd) => {
                          sd.dates.forEach((d) => {
                            if (
                              d.date &&
                              this.compareDatesNormalized(d.date, date.date)
                            ) {
                              tempClassToday = `${d.dayState}-show-tile saved-tile selected-show-tile`;
                            }
                          });
                        }, tempClassToday);
                      }
                      return tempClassToday;
                    }
                  } else {
                    var tempClass = '';
                    if (this.state.proposedShowDates.length > 0) {
                      this.state.proposedShowDates.forEach((pd) => {
                        if (this.compareDatesNormalized(pd.date, date.date)) {
                          tempClass += ` ${pd.dayState}-show-tile selected-show-tile `;
                        }
                      }, tempClass);
                    }
                    if (this.state.savedShowDates.length > 0) {
                      this.state.savedShowDates.forEach((sd) => {
                        sd.dates.forEach((d) => {
                          if (
                            d.date &&
                            this.compareDatesNormalized(d.date, date.date)
                          ) {
                            if (this.state.isShowActive) {
                              tempClass += ` ${d.dayState}-show-tile saved-active-tile`;
                            } else {
                              tempClass += ` ${d.dayState}-show-tile saved-tile `;
                            }
                          }
                        });
                      }, tempClass);
                    }
                    tempClass =
                      tempClass === '' ? 'active-calendar-tile' : tempClass;
                    return this.state.isShowActive ||
                      (!this.state.isShowActive &&
                        tempClass !== 'active-calendar-tile')
                      ? tempClass
                      : 'regular-calendar-tile';
                  }
                }}
                onChange={this.onCalendarChange}
              />
              <div className="calendar-action-bar">
                <Button
                  className="black-and-white-primary-btn"
                  disabled={this.state.isShowActive && !this.state.canSaveShow}
                  onClick={this.saveShow}
                >
                  {this.state.isShowActive ? 'Save' : 'Add'}
                </Button>

                {this.state.isShowActive && (
                  <InputGroup className="show-name">
                    <Form.Control
                      id="showName"
                      name="showName"
                      aria-describedby="showName"
                      placeholder="Show Name"
                      className={
                        !this.state.canSaveShow && !this.state.focused_showName
                          ? 'invalid-show-name'
                          : ''
                      }
                      onChange={this.onChangeShowName}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                    />
                  </InputGroup>
                )}
                {this.state.isShowActive && (
                  <Button
                    className="black-and-white-primary-btn"
                    onClick={() => {
                      this.setState({
                        isShowActive: false,
                        proposedShowDates: [],
                        focusedDay: '',
                        currentProposedDate: '',
                        focusedDayState: 'none',
                      });
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  className="instructions black-and-white-primary-btn"
                  onClick={() => {
                    this.openModalForInstructions();
                  }}
                >
                  Instructions
                </Button>
                <span className="legend">
                  <span className="matinee-time time-box">M</span>
                  <span className="both-time time-box">M/E</span>
                  <span className="evening-time time-box">E</span>
                  <span className="legend-label">Matinee</span>
                  <span className="legend-label">Both</span>
                  <span className="legend-label">Evening</span>
                </span>
              </div>
            </Col>
            <Col
              lg={{ span: 4, order: 1 }}
              md={{ span: 12, order: 2 }}
              sm={{ span: 12, order: 2 }}
              xs={{ span: 12, order: 2 }}
            >
              <Card className="schedule-card">
                <Card.Header className="show-dates-title">
                  Show Dates
                </Card.Header>
                <ListGroup className="show-dates">
                  {this.state.currentProposedDate && this.state.isShowActive && (
                    <ListGroup.Item className="current-proposed-date">
                      <span className="proposed-show-dates ">
                        {this.state.currentProposedDate}
                      </span>
                      <span className="proposed-show-times">
                        <span
                          className={
                            this.state.focusedDayState === 'both'
                              ? 'both-time matinee-time time-box'
                              : 'matinee-time time-box'
                          }
                        >
                          {this.state.focusedDayState === 'matinee' ||
                          this.state.focusedDayState === 'both'
                            ? 'M'
                            : ' '}
                        </span>
                        <span
                          className={
                            this.state.focusedDayState === 'both'
                              ? 'both-time evening-time time-box'
                              : 'evening-time time-box'
                          }
                        >
                          {this.state.focusedDayState === 'evening' ||
                          this.state.focusedDayState === 'both'
                            ? 'E'
                            : ' '}
                        </span>
                      </span>
                    </ListGroup.Item>
                  )}

                  {this.state.proposedShowDates.length > 0 &&
                    this.state.proposedShowDates.map(function (pd) {
                      var normalizedDate = new Date(pd.date);
                      return (
                        <ListGroup.Item
                          key={pd.date}
                          className="proposed-show-row"
                        >
                          <span className="proposed-show-dates">{`${
                            normalizedDate.getMonth() + 1
                          }/${normalizedDate.getDate()}/${normalizedDate.getFullYear()}`}</span>
                          <span className="proposed-show-times">
                            <span
                              className={
                                pd.dayState === 'both'
                                  ? 'both-time matinee-time time-box'
                                  : 'matinee-time time-box'
                              }
                            >
                              {pd.dayState === 'matinee' ||
                              pd.dayState === 'both'
                                ? 'M'
                                : ' '}
                            </span>
                            <span
                              className={
                                pd.dayState === 'both'
                                  ? 'both-time evening-time time-box'
                                  : 'evening-time time-box'
                              }
                            >
                              {pd.dayState === 'evening' ||
                              pd.dayState === 'both'
                                ? 'E'
                                : ' '}
                            </span>
                          </span>
                          <span>
                            <Button
                              className="icon-btn show-dates"
                              onClick={() => this.removeShowDate(pd)}
                            >
                              <FontAwesomeIcon icon={faTimesCircle} />
                            </Button>
                          </span>
                        </ListGroup.Item>
                      );
                    }, this)}

                  {this.props.shows &&
                    this.state.savedShowDates.length > 0 &&
                    this.state.savedShowDates.map(function (savedShow) {
                      var showBlock = savedShow.dates.map((d) => {
                        var normalizedDate = new Date(d.date);
                        return (
                          <ListGroup.Item
                            key={savedShow._id + d.date.toString()}
                            className={
                              'saved-show-row ' +
                              (this.compareDatesNormalized(
                                d.date,
                                this.state.focusedDay
                              )
                                ? 'focused-show-row'
                                : '')
                            }
                          >
                            <span className="proposed-show-dates">{`${
                              normalizedDate.getMonth() + 1
                            }/${normalizedDate.getDate()}/${normalizedDate.getFullYear()}`}</span>
                            <span className="proposed-show-times">
                              <span
                                className={
                                  d.dayState === 'both'
                                    ? 'both-time matinee-time time-box'
                                    : 'matinee-time time-box'
                                }
                              >
                                {d.dayState === 'matinee' ||
                                d.dayState === 'both'
                                  ? 'M'
                                  : ' '}
                              </span>
                              <span
                                className={
                                  d.dayState === 'both'
                                    ? 'both-time evening-time time-box'
                                    : 'evening-time time-box'
                                }
                              >
                                {d.dayState === 'evening' ||
                                d.dayState === 'both'
                                  ? 'E'
                                  : ' '}
                              </span>
                            </span>
                            <span>
                              <Button
                                className="icon-btn show-dates"
                                onClick={() =>
                                  this.removeShowDate(d, 'saved', savedShow._id)
                                }
                              >
                                <FontAwesomeIcon icon={faTimesCircle} />
                              </Button>
                            </span>
                          </ListGroup.Item>
                        );
                      });
                      return (
                        <ListGroup.Item
                          key={savedShow._id + savedShow.title}
                          className="saved-show-block"
                        >
                          <InputGroup className="title-change">
                            <Overlay
                              target={this[`target${savedShow._id}`]}
                              show={
                                this.state.showTooltipForId === savedShow._id &&
                                (whichCharactersAreInvalidByRegexPattern(
                                  this.state.invalidShowName,
                                  RegexPatternConstants.INVERSE_PATTERN
                                    .TEXT_VALIDATION_INVERSE
                                ) !== '' ||
                                  this.state.invalidShowName === '')
                              }
                              placement="top"
                              className="tooltip-validation"
                            >
                              <Tooltip>
                                {this.state.invalidShowName === ''
                                  ? 'Show name may not be empty'
                                  : 'Show name must not contain the characters: ' +
                                    whichCharactersAreInvalidByRegexPattern(
                                      this.state.invalidShowName,
                                      RegexPatternConstants.INVERSE_PATTERN
                                        .TEXT_VALIDATION_INVERSE
                                    )}
                              </Tooltip>
                            </Overlay>
                            <div className="editable-title-wrapper">
                              <span className="form-control-wrapper">
                                <Form.Control
                                  defaultValue={savedShow.title}
                                  placeholder="Show Name"
                                  ref={(input) => {
                                    this[`target${savedShow._id}`] = input;
                                  }}
                                  onChange={(e) => {
                                    this.setState({
                                      currentShowTitle: e.target.value,
                                    });
                                  }}
                                  onFocus={() => {
                                    this.setState({
                                      showTooltipForId: '',
                                      invalidShowName: '',
                                    });
                                  }}
                                  onBlur={(e) => {
                                    if (
                                      isValidByRegexPattern(
                                        this.state.currentShowTitle,
                                        RegexPatternConstants.PATTERN
                                          .TEXT_VALIDATION
                                      )
                                    ) {
                                      this.handleTitleChange(savedShow._id);
                                    } else {
                                      this.setState(
                                        {
                                          showTooltipForId: savedShow._id,
                                          invalidShowName: e.target.value,
                                        },
                                        () => {
                                          e.target.value = savedShow.title;
                                        }
                                      );
                                    }
                                  }}
                                />
                              </span>
                              <FontAwesomeIcon
                                icon={faPen}
                                onClick={() => {
                                  this[`target${savedShow._id}`].focus();
                                }}
                              />
                            </div>
                          </InputGroup>
                          {showBlock}
                        </ListGroup.Item>
                      );
                    }, this)}
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row className="dashboard-section">
            <Col md={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }}>
              <div className="users-table-header">
                <div className="user-title-row">
                  <h5 className="text-left table-title">Users</h5>
                  <Form.Group className="inline-check">
                    <Form.Check
                      type="checkbox"
                      label="Show archived"
                      onChange={() => {
                        this.setState({
                          showArchivedUsers: !this.state.showArchivedUsers,
                        });
                      }}
                    />
                  </Form.Group>
                </div>
                <InputGroup className="search-bar">
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search users by name or email"
                    onChange={this.onSearch}
                  />
                </InputGroup>
              </div>
              <ListGroup className="user-info-row-title-group">
                <ListGroup.Item className="user-info-row-title">
                  <span className="user-info-row-item">
                    Name{' '}
                    <div className="sorting-buttons">
                      <Button onClick={() => this.onSortBy('displayName')}>
                        <FontAwesomeIcon
                          className={
                            (this.state.sortedBy === 'displayNameUp'
                              ? 'sorted-by'
                              : ' ') + ' fa-fw'
                          }
                          icon={faCaretUp}
                        />
                        <FontAwesomeIcon
                          className={
                            (this.state.sortedBy === 'displayNameDown'
                              ? 'sorted-by'
                              : ' ') + ' fa-fw'
                          }
                          icon={faCaretDown}
                        />
                      </Button>
                    </div>
                  </span>
                  <span className="user-info-row-item hide-if-small">
                    Email{' '}
                    <div className="sorting-buttons">
                      <Button onClick={() => this.onSortBy('email')}>
                        <FontAwesomeIcon
                          className={
                            (this.state.sortedBy === 'emailUp'
                              ? 'sorted-by'
                              : ' ') + ' fa-fw'
                          }
                          icon={faCaretUp}
                        />
                        <FontAwesomeIcon
                          className={
                            (this.state.sortedBy === 'emailDown'
                              ? 'sorted-by'
                              : ' ') + ' fa-fw'
                          }
                          icon={faCaretDown}
                        />
                      </Button>
                    </div>
                  </span>
                  <span className="user-info-row-item">
                    Permissions{' '}
                    <div className="sorting-buttons">
                      <Button onClick={() => this.onSortBy('permissions')}>
                        <FontAwesomeIcon
                          className={
                            (this.state.sortedBy === 'permissionsUp'
                              ? 'sorted-by'
                              : ' ') + ' fa-fw'
                          }
                          icon={faCaretUp}
                        />
                        <FontAwesomeIcon
                          className={
                            (this.state.sortedBy === 'permissionsDown'
                              ? 'sorted-by'
                              : ' ') + ' fa-fw'
                          }
                          icon={faCaretDown}
                        />
                      </Button>
                    </div>
                  </span>
                </ListGroup.Item>
              </ListGroup>
              <ListGroup>
                {this.state.users &&
                  this.state.users.map(function (u) {
                    if (
                      (this.state.showArchivedUsers &&
                        u.permissions === 'archived') ||
                      u.permissions !== 'archived'
                    )
                      return (
                        <ListGroup.Item
                          className={
                            (u.permissions === 'archived'
                              ? 'archived-user-info-row'
                              : 'user-info-row') +
                            (u._id.toString() === this.props.user._id.toString()
                              ? ' current-user'
                              : '')
                          }
                          key={u._id}
                        >
                          <span className="user-info-row-item">
                            {truncate(u.displayName, 15)}
                          </span>
                          <span
                            className={
                              (u.email && u.profileType !== 'google'
                                ? ''
                                : 'muted ') + 'user-info-row-item hide-if-small'
                            }
                          >
                            {u.email
                              ? truncate(u.email, 26)
                              : u.profileType === 'google'
                              ? 'Google user'
                              : 'No email yet'}
                          </span>
                          <span className="user-info-row-item">
                            {truncate(u.permissions, 20)}
                          </span>
                          <div className="user-info-row-item action-buttons">
                            {u.permissions !== 'archived' && (
                              <span>
                                <Button
                                  className="circular-btn"
                                  onClick={() => {
                                    this.openModalWithUserInfo(u);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className="fa-fw"
                                    icon={faEdit}
                                  />
                                </Button>
                                <Button
                                  className="circular-btn margin-left"
                                  onClick={() => {
                                    this.openModalForArchiveUser(u);
                                  }}
                                  disabled={
                                    this.props.user._id.toString() === u._id
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="fa-fw"
                                    icon={faTrash}
                                  />
                                </Button>
                              </span>
                            )}
                            {u.permissions === 'archived' && (
                              <span>
                                <Button
                                  className="circular-btn archive-btn"
                                  onClick={() => {
                                    this.restoreUser(u);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className="fa-fw"
                                    icon={faTrashRestore}
                                  />
                                </Button>
                                <Button
                                  className="circular-btn margin-left"
                                  onClick={() => {
                                    this.openModalForDeleteUser(u);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className="fa-fw"
                                    icon={faTrash}
                                  />
                                </Button>
                              </span>
                            )}
                          </div>
                        </ListGroup.Item>
                      );
                  }, this)}
              </ListGroup>
            </Col>
          </Row>
        </Container>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          {this.state.modalAction === 'instructions' && (
            <Modal.Body>
              <ListGroup>
                <ListGroup.Item>
                  {"To add a show to the schedule, click 'Add'."}
                </ListGroup.Item>
                <ListGroup.Item>
                  {
                    'Select show dates by clicking on the dates in the calendar.'
                  }
                </ListGroup.Item>
                <ListGroup.Item>
                  {
                    'Clicking multiple times will cycle through the possible show times: evening, matinee or both.'
                  }
                </ListGroup.Item>
                <ListGroup.Item>
                  {"Add a show title and click 'Save'."}
                </ListGroup.Item>
                <ListGroup.Item>
                  {
                    'The dates, times, and show name can be edited by clicking on the saved date and editing them in the side bar'
                  }
                </ListGroup.Item>
              </ListGroup>
            </Modal.Body>
          )}
          {this.state.modalAction === 'edit' && (
            <Modal.Body>
              <Form.Group>
                <Form.Label htmlFor="firstName">
                  First Name{' '}
                  {this.state.permissions === 'ticket-only'
                    ? ' (cannot be edited for ticket-only users)'
                    : ''}
                </Form.Label>
                <Form.Control
                  id="firstName"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  autoComplete="given-name"
                  value={this.state.firstName}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  readOnly={this.state.permissions === 'ticket-only'}
                  disabled={this.state.permissions === 'ticket-only'}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={
                    this.state.error_firstName && this.state.focused_firstName
                      ? ''
                      : 'visible'
                  }
                >
                  {this.state.error_firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="lastName">
                  Last Name
                  {this.state.permissions === 'ticket-only'
                    ? ' (cannot be edited for ticket-only users)'
                    : ''}
                </Form.Label>

                <Form.Control
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  autoComplete="family-name"
                  value={this.state.lastName}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  readOnly={this.state.permissions === 'ticket-only'}
                  disabled={this.state.permissions === 'ticket-only'}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={
                    this.state.error_lastName && this.state.focused_lastName
                      ? ''
                      : 'visible'
                  }
                >
                  {this.state.error_lastName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="email">
                  Email
                  {this.state.email !== '' ||
                  this.state.permissions === 'ticket-only'
                    ? ' (cannot be edited, only added)'
                    : ''}
                </Form.Label>
                <Form.Control
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  autoComplete="name"
                  value={this.state.email}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  readOnly={this.state.email !== ''}
                  disabled={this.state.email !== ''}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={
                    this.state.error_email && this.state.focused_email
                      ? ''
                      : 'visible'
                  }
                >
                  {this.state.error_email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Permissions
                  {this.state.permissions === 'ticket-only'
                    ? ' (cannot be edited for ticket-only users)'
                    : ''}
                </Form.Label>
                <Form.Control
                  as="select"
                  name="permissions"
                  defaultValue={this.state.permissions}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  readOnly={this.state.permissions === 'ticket-only'}
                  disabled={this.state.permissions === 'ticket-only'}
                >
                  <option>ticket-only</option>
                  <option>personal</option>
                  <option>box-office</option>
                  <option>admin</option>
                </Form.Control>
              </Form.Group>

              {this.state.expirationDate && (
                <Form.Group>
                  <Form.Label>FlexPass Expiration Date</Form.Label>
                  <DatePicker
                    selected={
                      new Date(
                        this.normalizeDatesToString(this.state.expirationDate)
                      )
                    }
                    onChange={(date) => {
                      this.setState({ expirationDate: date.toString() });
                    }}
                  />
                </Form.Group>
              )}
            </Modal.Body>
          )}
          {this.state.modalAction === 'archive' && (
            <Modal.Body>
              Are you sure you want to archive {this.state.firstName}{' '}
              {this.state.lastName}?
            </Modal.Body>
          )}
          {this.state.modalAction === 'delete' && (
            <Modal.Body>
              Are you sure you want to delete {this.state.firstName}{' '}
              {this.state.lastName}?
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              variant={modalActionButtonColorMap[`${this.state.modalAction}`]}
              onClick={this.onHandleModalSubmit}
            >
              {this.state.modalActionButton}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

Dashboard.propTypes = {
  user: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  shows: PropTypes.arrayOf(PropTypes.object),
  from: PropTypes.string.isRequired,
  permissions: PropTypes.string.isRequired,
  errorMessagePassedOn: PropTypes.string,
  successMessagePassedOn: PropTypes.string,
  clearAlertMessages: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  updateSchedule: PropTypes.func.isRequired,
  fetchSchedule: PropTypes.func.isRequired,
  rerouteWithComponentLink: PropTypes.func.isRequired,
  onUpdateUserFormSubmit: PropTypes.func.isRequired,
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.object,
      datePurchased: PropTypes.string,
      expiryDate: PropTypes.string,
      remainingUses: PropTypes.number,
      previousUses: PropTypes.arrayOf(
        PropTypes.shape({
          dateUsed: PropTypes.string,
          show: PropTypes.string,
          updatedBy: PropTypes.string,
        })
      ),
    })
  ),
};
