export const isStatusOk = (status) => {
  return status > 199 && status < 300;
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length == 0;
};

export const isValidByRegexPattern = (text, pattern) => {
  return pattern.test(String(text).toLowerCase());
};

export const whichCharactersAreInvalidByRegexPattern = (text, pattern) => {
  var invalidChars = text.match(pattern);
  if (invalidChars) {
    return invalidChars.join('');
  }
  return '';
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const truncate = (string, length) => {
  if (string !== undefined && string !== '' && string.length > 0) {
    return string.length > length
      ? string.substr(0, length - 1) + '...'
      : string;
  } else {
    return '';
  }
};

export const redirectMap = (userPermissions) => {
  if (userPermissions === 'ticket-only') {
    return '/login';
  } else if (
    userPermissions === 'personal' ||
    userPermissions === 'box-office'
  ) {
    return '/tickets';
  } else if (userPermissions === 'admin') {
    return '/';
  } else {
    return '/login';
  }
};
