import 'react-datepicker/dist/react-datepicker.css';

import {
  faCaretDown,
  faCaretUp,
  faChevronDown,
  faChevronUp,
  faMinusCircle,
  faPen,
  faPlus,
  faPlusCircle,
  faSyncAlt,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Overlay from 'react-bootstrap/Overlay';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import DatePicker from 'react-datepicker';
import { Redirect } from 'react-router-dom';

import * as ErrorMessagingConstants from '../../constants/error-messaging';
import * as RegexPatternConstants from '../../constants/regex-patterns';
import {
  isValidByRegexPattern,
  toTitleCase,
  truncate,
  whichCharactersAreInvalidByRegexPattern,
} from '../../helpers/helperFunctions';
import AlertDismissible from '../Alert/AlertDismissible';

const validInputNames = ['email', 'firstName', 'lastName', 'numberOfTickets'];

export default class Tickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      tickets: [],
      sortedBy: 'displayNameDown',
      showModal: false,
      displayName: '',
      firstName: '',
      lastName: '',
      email: '',
      numberOfTickets: 12,
      error_firstName: '',
      error_larstName: '',
      error_email: '',
      error_numberOfTickets: '',
      error_showName: '',
      focused_firstName: '',
      focused_larstName: '',
      focused_email: '',
      focused_numberOfTickets: '',
      focused_showName: '',
      modalAction: '',
      todaysShow: '',
      todaysShowState: '',
      validated: false,
      showTooltipForId: '',
      invalidShowName: '',
      ticketHistoryShowName: '',
      ticketHistoryShowDate: '',
      showArchivedUsers: false,
    };

    this.onFetchJotForms = this.onFetchJotForms.bind(this);
    this.ticketMap = this.ticketMap.bind(this);
    this.onRemoveTicket = this.onRemoveTicket.bind(this);
    this.onAddTicket = this.onAddTicket.bind(this);
    this.onDeleteHistory = this.onDeleteHistory.bind(this);
    this.reroute = this.reroute.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChangeShowName = this.onChangeShowName.bind(this);
    this.onManuallyAddTicket = this.onManuallyAddTicket.bind(this);
    this.ticketClass = this.ticketClass.bind(this);
    this.onHandleModalSubmit = this.onHandleModalSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.reroute = this.reroute.bind(this);
    this.setInputEmptyValidationMessage = this.setInputEmptyValidationMessage.bind(
      this
    );
    this.isValidInputName = this.isValidInputName.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.onModalOpen = this.onModalOpen.bind(this);
    this.setInputFocus = this.setInputFocus.bind(this);
    this.onSortBy = this.onSortBy.bind(this);
    this.sortBy = this.sortBy.bind(this);
    this.isFormValidToSubmit = this.isFormValidToSubmit.bind(this);
    this.whatShowIsToday = this.whatShowIsToday.bind(this);
    this.getShowState = this.getShowState.bind(this);
    this.normalizeDatesToString = this.normalizeDatesToString.bind(this);
    this.handleTicketHistoryTitleChange = this.handleTicketHistoryTitleChange.bind(
      this
    );
    this.sortByOpeningDates = this.sortByOpeningDates.bind(this);
    this.clearInputValidationMessage = this.clearInputValidationMessage.bind(
      this
    );
  }

  normalizeDatesToString(dateString) {
    var date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  compareDatesNormalized(dateString1, dateString2) {
    var date1 = new Date(dateString1);
    var date2 = new Date(dateString2);
    return (
      `${date1.getMonth() + 1}/${date1.getDate()}/${date1.getFullYear()}` ===
      `${date2.getMonth() + 1}/${date2.getDate()}/${date2.getFullYear()}`
    );
  }

  getShowState(dateItem) {
    var showState = '';
    if (
      dateItem !== undefined &&
      dateItem.dayState &&
      (dateItem.dayState === 'evening' || dateItem.dayState === 'matinee')
    ) {
      showState = toTitleCase(dateItem.dayState);
    } else if (new Date().getHours() > 17 || new Date().getHours() < 2) {
      showState = 'Evening';
    } else {
      showState = 'Matinee';
    }
    return showState;
  }

  whatShowIsToday() {
    var whatShow = { title: 'default', showState: this.getShowState() };
    this.state.shows.forEach((s) => {
      if (s.dates) {
        s.dates.forEach((d) => {
          if (this.compareDatesNormalized(d.date, new Date().toString())) {
            whatShow.title = s.title;
            whatShow.showState = this.getShowState(d);
          }
        });
      }
    });
    return whatShow;
  }

  sortByOpeningDates() {
    return function (a, b) {
      if (a.dates[0].date > b.dates[0].date) {
        return 1;
      } else if (a.dates[0].date < b.dates[0].date) {
        return -1;
      }
      return 0;
    };
  }

  handleClose() {
    this.setState({
      showModal: false,
      modalAction: '',
      displayName: '',
      firstName: '',
      lastName: '',
      email: '',
      numberOfTickets: 12,
      error_firstName: '',
      error_larstName: '',
      error_email: '',
      error_numberOfTickets: '',
      focused_firstName: '',
      focused_larstName: '',
      focused_email: '',
      focused_numberOfTickets: '',
      shows: '',
    });
  }

  onClick(eventKey) {
    if (this.state.activeTicket === eventKey) {
      this.setState({
        activeTicket: null,
      });
    } else {
      this.setState({
        activeTicket: eventKey,
      });
    }
  }

  onManuallyAddTicket(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.currentTarget.checkValidity() && this.isFormValidToSubmit()) {
      this.setState({ validated: true });
      var ticketInfo = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        remainingUses: this.state.numberOfTickets,
      };
      this.props.onAddTicketManuallySubmit(ticketInfo);
    } else {
      this.setState({ validated: false });
    }
    this.handleClose();
  }

  onModalOpen(action) {
    this.setState({ showModal: true, modalAction: action });
  }

  handleTicketHistoryTitleChange(user, useId, oldTitle, oldDate, ticket) {
    var newShowName =
      this.state.ticketHistoryShowName !== '' &&
      this.state.ticketHistoryShowName !== oldTitle
        ? this.state.ticketHistoryShowName
        : oldTitle;
    var newShowDate =
      this.state.ticketHistoryShowDate !== '' &&
      this.state.ticketHistoryShowDate !== oldDate
        ? this.state.ticketHistoryShowDate
        : oldDate;

    var updated = false;
    if (ticket.previousUses) {
      ticket.previousUses.forEach((u) => {
        if (u._id === useId) {
          u.show = newShowName;
          u.dateUsed = newShowDate;
          updated = true;
        }
      });
    }

    if (updated) {
      this.props.updateTicketHistory(user, ticket, this.state.sortedBy);
    }
  }

  onRemoveTicket(e, user) {
    e.cancelBubble = true;
    e.stopPropagation();
    var showName = this.state.todaysShow
      ? this.state.todaysShow +
        (this.state.todaysShowState ? ': ' + this.state.todaysShowState : '')
      : 'No Show Title';

    this.props.updateRemainingTickets(
      user,
      showName,
      'remove',
      this.state.sortedBy
    );
  }

  onAddTicket(e, user) {
    e.cancelBubble = true;
    e.stopPropagation();
    var showName = this.state.todaysShow ? this.state.todaysShow : '';
    this.props.updateRemainingTickets(
      user,
      showName,
      'add',
      this.state.sortedBy
    );
  }

  onDeleteHistory(e, ticketId, useId) {
    e.cancelBubble = true;
    e.stopPropagation();
    this.props.removeHistoryItem(ticketId, useId, this.state.sortedBy);
  }

  onFetchJotForms() {
    try {
      this.props.updateFlexPassDB('fetch');
    } catch (err) {
      console.log(err);
    }
  }

  onSearch(e) {
    try {
      this.setState({
        tickets: this.props.tickets.filter((ticket) => {
          var isValidSearchResult = false;
          if (ticket.user.displayName !== undefined) {
            isValidSearchResult = ticket.user.displayName
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          }
          if (!isValidSearchResult && ticket.user.email !== undefined) {
            isValidSearchResult = ticket.user.email
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          }
          return isValidSearchResult;
        }),
      });
    } catch (err) {
      console.error(err);
    }
  }

  setInputEmptyValidationMessage(name) {
    if (name !== 'numberOfTickets') {
      this.setState({
        [`error_${name}`]: ErrorMessagingConstants.NAME_ATTRIBUTE_TO_ERROR_MAP
          .EMPTY[name],
      });
    }
  }

  clearInputValidationMessage(name) {
    this.setState({
      [`error_${name}`]: '',
    });
  }

  setInputFocus(name) {
    let stateObj = {
      focused_firstName: name === 'firstName',
      focused_lastName: name === 'lastName',
      focused_email: name === 'email',
    };
    this.setState(stateObj);
  }

  validateInput(name, value) {
    if (
      (name !== 'numberOfTickets' &&
        !isValidByRegexPattern(
          value,
          RegexPatternConstants.NAME_ATTRIBUTE_TO_PATTERN_MAP[name]
        )) ||
      (name === 'numberOfTickets' && (value < 1 || value > 48))
    ) {
      this.setState({
        [`error_${name}`]: ErrorMessagingConstants.NAME_ATTRIBUTE_TO_ERROR_MAP
          .INVALID[name],
      });
    }
  }

  isValidInputName(name) {
    return validInputNames.includes(name);
  }

  isFormValidToSubmit() {
    validInputNames.forEach((inputName) => {
      if (this.state[`error_${inputName}`] || !this.state[`${inputName}`]) {
        return false;
      }
    });
    return true;
  }

  onChangeShowName(e) {
    var show = { title: e.target.value, dayState: this.getShowState() };
    this.state.shows.forEach((s) => {
      if (s._id === e.target.value) {
        show = s.title;
      }
    });

    if (
      isValidByRegexPattern(show, RegexPatternConstants.PATTERN.TEXT_VALIDATION)
    ) {
      this.setState({ todaysShow: show.title, todaysShowState: show.dayState });
    } else {
      this.setState({
        error_showName:
          'Show name must not contain the characters: ' +
          whichCharactersAreInvalidByRegexPattern(
            show,
            RegexPatternConstants.INVERSE_PATTERN.TEXT_VALIDATION_INVERSE
          ),
      });
    }
  }

  onChange(e) {
    try {
      if (this.isValidInputName(e.target.name)) {
        if (
          !e.target.value &&
          e.target.name !== 'email' &&
          this.state.modalAction !== 'addTicket'
        ) {
          this.setState({ validated: false });
          this.setInputEmptyValidationMessage(e.target.name);
        } else {
          this.setState({ validated: true });
          this.clearInputValidationMessage(e.target.name);
        }

        if (e.target.name && e.target.value) {
          this.validateInput(e.target.name, e.target.value);
        }

        this.setState({ [e.target.name]: e.target.value });
      }
    } catch (err) {
      console.error(err);
    }
  }

  onFocus(e) {
    this.setInputFocus(e.target.name);
  }

  onBlur(e) {
    this.setState({ [`focused_${e.target.name}`]: false });
  }

  onHandleModalSubmit(e) {
    if (this.state.modalAction === 'addTicket') {
      this.onManuallyAddTicket(e);
    }
  }

  ticketClass(ticket) {
    var className = '';
    if (ticket.remainingUses === 0) {
      className += 'no-tickets-row';
    }
    if (
      ticket.user.permissions === 'archived' &&
      this.state.showArchivedUsers
    ) {
      className += ' archived-tickets-row';
    } else if (
      ticket.user.permissions === 'archived' &&
      !this.state.showArchivedUsers
    ) {
      className += ' display-none';
    }
    return className;
  }

  ticketMap(permissions) {
    try {
      if (this.state.tickets.length > 0) {
        return this.state.tickets.map(function (ticket, index) {
          if (ticket.previousUses.length > 0) {
            return (
              <Card key={ticket._id} className={this.ticketClass(ticket)}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={index + ''}
                  className="text-left tickets-row"
                  onClick={() => this.onClick(index + '')}
                >
                  <span>{truncate(ticket.user.displayName, 22)}</span>
                  <span className="hide-if-small">
                    {truncate(ticket.user.email, 26)}
                  </span>

                  <span className="ticket-counter">
                    {(permissions === 'admin' ||
                      permissions === 'box-office') && (
                      <Button
                        disabled={ticket.remainingUses <= 0}
                        className="icon-btn tickets"
                        onClick={(e) => this.onRemoveTicket(e, ticket.user)}
                      >
                        <FontAwesomeIcon icon={faMinusCircle} />
                      </Button>
                    )}
                    <Badge variant="light">{ticket.remainingUses}</Badge>
                    {(permissions === 'admin' ||
                      permissions === 'box-office') && (
                      <Button
                        className="icon-btn tickets"
                        onClick={(e) => this.onAddTicket(e, ticket.user)}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </Button>
                    )}
                  </span>
                  <span>
                    <FontAwesomeIcon
                      icon={
                        this.state.activeTicket === index + ''
                          ? faChevronUp
                          : faChevronDown
                      }
                    />
                  </span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={index + ''}>
                  <ListGroup>
                    {ticket.previousUses.map((use) => {
                      return (
                        <ListGroup.Item
                          key={use._id}
                          className="previous-shows"
                        >
                          {(permissions === 'admin' ||
                            permissions === 'box-office') && (
                            <InputGroup className="title-change">
                              <Overlay
                                target={this[`target${use._id}_title`]}
                                show={
                                  this.state.showTooltipForId === use._id &&
                                  (whichCharactersAreInvalidByRegexPattern(
                                    this.state.invalidShowName,
                                    RegexPatternConstants.INVERSE_PATTERN
                                      .TEXT_VALIDATION_INVERSE
                                  ) !== '' ||
                                    this.state.invalidShowName === '')
                                }
                                placement="top"
                                className="tooltip-validation"
                              >
                                <Tooltip>
                                  {this.state.invalidShowName === ''
                                    ? 'Show name may not be empty'
                                    : 'Show name must not contain the characters: ' +
                                      whichCharactersAreInvalidByRegexPattern(
                                        this.state.invalidShowName,
                                        RegexPatternConstants.INVERSE_PATTERN
                                          .TEXT_VALIDATION_INVERSE
                                      )}
                                </Tooltip>
                              </Overlay>
                              <div className="editable-ticket-history-wrapper">
                                <span className="form-control-wrapper">
                                  <Form.Control
                                    defaultValue={use.show}
                                    placeholder="Show Name"
                                    ref={(input) => {
                                      this[`target${use._id}_title`] = input;
                                    }}
                                    onChange={(e) => {
                                      this.setState({
                                        ticketHistoryShowName: e.target.value,
                                      });
                                    }}
                                    onFocus={() => {
                                      this.setState({
                                        showTooltipForId: '',
                                        invalidShowName: '',
                                      });
                                    }}
                                    onBlur={(e) => {
                                      if (
                                        isValidByRegexPattern(
                                          this.state.ticketHistoryShowName,
                                          RegexPatternConstants.PATTERN
                                            .TEXT_VALIDATION
                                        )
                                      ) {
                                        this.handleTicketHistoryTitleChange(
                                          ticket.user._id,
                                          use._id,
                                          use.show,
                                          use.dateUsed,
                                          ticket
                                        );
                                      } else {
                                        this.setState(
                                          {
                                            showTooltipForId: use._id,
                                            invalidShowName: e.target.value,
                                          },
                                          () => {
                                            e.target.value = use.show;
                                          }
                                        );
                                      }
                                    }}
                                  />
                                </span>
                                <FontAwesomeIcon
                                  icon={faPen}
                                  onClick={() => {
                                    this[`target${use._id}_title`].focus();
                                  }}
                                />
                              </div>
                            </InputGroup>
                          )}
                          {permissions === 'personal' && <div>{use.show}</div>}
                          <div className="date-wrapper">
                            <DatePicker
                              selected={
                                new Date(
                                  this.normalizeDatesToString(use.dateUsed)
                                )
                              }
                              onChange={(date) => {
                                this.setState(
                                  { ticketHistoryShowDate: date.toString() },
                                  () => {
                                    this.handleTicketHistoryTitleChange(
                                      ticket.user._id,
                                      use._id,
                                      use.show,
                                      use.dateUsed,
                                      ticket
                                    );
                                  }
                                );
                              }}
                            />
                          </div>
                          {(permissions === 'admin' ||
                            permissions === 'box-office') && (
                            <Button
                              className="icon-btn"
                              onClick={(e) =>
                                this.onDeleteHistory(e, ticket._id, use._id)
                              }
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="fa-fw"
                              />
                            </Button>
                          )}
                        </ListGroup.Item>
                      );
                    }, this)}
                  </ListGroup>
                </Accordion.Collapse>
              </Card>
            );
          } else {
            return (
              <Card key={ticket._id} className={this.ticketClass(ticket)}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={index + ''}
                  className="text-left tickets-row"
                  onClick={() => this.onClick(index + '')}
                >
                  <span>{ticket.user.displayName}</span>
                  <span className="hide-if-small">{ticket.user.email}</span>
                  <span className="ticket-counter">
                    {(permissions === 'admin' ||
                      permissions === 'box-office') && (
                      <Button
                        disabled={ticket.remainingUses <= 0}
                        className="icon-btn tickets"
                        onClick={(e) => this.onRemoveTicket(e, ticket.user)}
                      >
                        <FontAwesomeIcon icon={faMinusCircle} />
                      </Button>
                    )}
                    <Badge variant="light">{ticket.remainingUses}</Badge>
                    {(permissions === 'admin' ||
                      permissions === 'box-office') && (
                      <Button
                        className="icon-btn tickets"
                        onClick={(e) => this.onAddTicket(e, ticket.user)}
                      >
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          className="tickets"
                        />
                      </Button>
                    )}
                  </span>

                  <span>
                    <FontAwesomeIcon
                      icon={
                        this.state.activeTicket === index + ''
                          ? faChevronUp
                          : faChevronDown
                      }
                    />
                  </span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={index + ''}>
                  <ListGroup>
                    <ListGroup.Item>
                      {"Looks like you haven't used this flexpass yet!"}
                    </ListGroup.Item>
                  </ListGroup>
                </Accordion.Collapse>
              </Card>
            );
          }
        }, this);
      } else {
        return (
          <ListGroup>
            <ListGroup.Item>
              {"Looks like you don't have a flexpass yet!"}
            </ListGroup.Item>
          </ListGroup>
        );
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tickets !== this.props.tickets) {
      this.setState({
        tickets: this.props.tickets.sort(this.sortBy('name', false)),
      });
      this.ticketMap();
    }
    if (prevProps.shows !== this.props.shows) {
      this.setState(
        {
          shows: this.props.shows.sort(this.sortByOpeningDates()),
        },
        () => {
          if (this.whatShowIsToday().title !== 'default') {
            this.setState({
              todaysShow: this.whatShowIsToday().title,
              todaysShowState: this.whatShowIsToday().showState,
            });
          }
        }
      );
    }
  }

  sortBy(category, reverse) {
    if (category === 'displayName') {
      return function (a, b) {
        if (
          a.user.permissions === 'archived' &&
          b.user.permissions !== 'archived'
        ) {
          return 1;
        }
        if (
          a.user.permissions !== 'archived' &&
          b.user.permissions === 'archived'
        ) {
          return -1;
        } else if (
          a.user[category].split(' ')[a.user[category].split(' ').length - 1] >
          b.user[category].split(' ')[b.user[category].split(' ').length - 1]
        ) {
          return reverse ? -1 : 1;
        } else if (
          a.user[category].split(' ')[a.user[category].split(' ').length - 1] <
          b.user[category].split(' ')[b.user[category].split(' ').length - 1]
        ) {
          return reverse ? 1 : -1;
        }
        return 0;
      };
    } else if (category !== 'remainingUses') {
      return function (a, b) {
        if (
          a.user.permissions === 'archived' &&
          b.user.permissions !== 'archived'
        ) {
          return 1;
        }
        if (
          a.user.permissions !== 'archived' &&
          b.user.permissions === 'archived'
        ) {
          return -1;
        }
        if (a.remainingUses === 0 && b.remainingUses !== 0) {
          return 1;
        } else if (a.remainingUses !== 0 && b.remainingUses === 0) {
          return -1;
        }
        if (a.user[category] > b.user[category]) {
          return reverse ? -1 : 1;
        } else if (a.user[category] < b.user[category]) {
          return reverse ? 1 : -1;
        }
        return 0;
      };
    } else {
      return function (a, b) {
        if (
          a.user.permissions === 'archived' &&
          b.user.permissions !== 'archived'
        ) {
          return 1;
        }
        if (
          a.user.permissions !== 'archived' &&
          b.user.permissions === 'archived'
        ) {
          return -1;
        }
        if (a.remainingUses === 0 && b.remainingUses !== 0) {
          return 1;
        }
        if (a.remainingUses !== 0 && b.remainingUses === 0) {
          return -1;
        }
        if (a[category] > b[category]) {
          return reverse ? 1 : -1;
        } else if (a[category] < b[category]) {
          return reverse ? -1 : 1;
        }
        return 0;
      };
    }
  }

  onSortBy(category) {
    if (this.state.sortedBy === `${category}Down`) {
      this.setState({
        sortedBy: `${category}Up`,
        tickets: this.state.tickets.sort(this.sortBy(category, true)),
      });
    } else {
      this.setState({
        sortedBy: `${category}Down`,
        tickets: this.state.tickets.sort(this.sortBy(category, false)),
      });
    }
  }

  componentDidMount() {
    if (this.props.tickets) {
      this.setState({
        tickets: this.props.tickets.sort(this.sortBy('name', false)),
      });
    }

    if (this.props.shows) {
      this.setState(
        {
          shows: this.props.shows.sort(this.sortByOpeningDates()),
        },
        () => {
          if (this.whatShowIsToday().title !== 'default') {
            this.setState({
              todaysShow: this.whatShowIsToday().title,
              todaysShowState: this.whatShowIsToday().showState,
            });
          }
        }
      );
    }

    this.ticketMap();
    document.title = 'Tickets';
  }

  reroute(pathTo, pathFrom) {
    this.props.rerouteWithComponentLink(pathTo, pathFrom);
  }

  render() {
    const { from } = this.props.from || { from: '/login' };
    let permissions = this.props.permissions || 'ticket-only';
    let errorMessagePassedOn = this.props.errorMessagePassedOn || '';
    let successMessagePassedOn = this.props.successMessagePassedOn || '';

    if (permissions === 'ticket-only') {
      return <Redirect to={from} />;
    }

    return (
      <>
        <Container className="main-container">
          {this.props.errorMessagePassedOn && (
            <AlertDismissible
              variant="danger"
              show={this.state.show}
              message={errorMessagePassedOn}
              clearAlertMessages={this.props.clearAlertMessages}
            />
          )}
          {this.props.successMessagePassedOn && (
            <AlertDismissible
              variant="success"
              show={this.state.show}
              message={successMessagePassedOn}
              clearAlertMessages={this.props.clearAlertMessages}
            />
          )}
          {(permissions === 'admin' || permissions === 'box-office') &&
            this.state.shows && (
              <Row className="dashboard-section">
                <span className="date">
                  {this.normalizeDatesToString(new Date().toString())}
                </span>
                <h5 className="todays-show-title">{"Today's Show"}</h5>

                {this.state.todaysShow && (
                  <div className="todays-show-subtitle">
                    {this.state.todaysShow +
                      (this.state.todaysShowState !== ''
                        ? '-' + this.state.todaysShowState
                        : '')}
                  </div>
                )}
                <div className="set-todays-show-wrapper">
                  <Form.Control
                    as="select"
                    defaultValue={'default'}
                    className="shows-dropdown"
                    onChange={this.onChangeShowName}
                  >
                    <option value="default" disabled={true}>
                      Select a show from this season
                    </option>
                    {this.state.shows.map((s) => {
                      var optionString = s.title;
                      if (s.dates.length > 0) {
                        optionString +=
                          ': ' + this.normalizeDatesToString(s.dates[0].date);
                        if (s.dates.length > 1) {
                          optionString +=
                            '-' +
                            this.normalizeDatesToString(
                              s.dates[s.dates.length - 1].date
                            );
                        }
                      }
                      return (
                        <option
                          key={s._id}
                          value={s._id}
                          selected={this.state.todaysShow === s.title}
                        >
                          {optionString}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <div className="or">OR</div>
                  <InputGroup>
                    <Form.Control
                      className="shows-input"
                      placeholder="Today's Show"
                      aria-label="Today's Show"
                      defaultValue=""
                      onFocus={() => {
                        this.setState({
                          focused_showName: true,
                          error_showName: '',
                          todaysShow: '',
                          todaysShowState: '',
                        });
                      }}
                      onBlur={() => {
                        this.setState({ focused_showName: false });
                      }}
                      onChange={this.onChangeShowName}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className={
                        this.state.error_showName &&
                        !this.state.focused_showName
                          ? 'visible'
                          : ''
                      }
                    >
                      {this.state.error_firstName}
                    </Form.Control.Feedback>
                  </InputGroup>
                </div>
              </Row>
            )}
          <Row className="dashboard-section">
            <Col md={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }}>
              <h5 className="table-title tickets-title">
                {permissions === 'personal' ? 'FlexPass' : 'FlexPasses'}
              </h5>
              <div className="tickets-table-header">
                {(permissions === 'admin' || permissions === 'box-office') && (
                  <div className="tickets-actions">
                    <InputGroup className="search-bar">
                      <Form.Control
                        placeholder="Search"
                        aria-label="Search tickets by name or email"
                        onChange={this.onSearch}
                      />
                    </InputGroup>
                    <div className="tickets-action-bar-wrapper">
                      <Form.Group className="inline-check">
                        <Form.Check
                          type="checkbox"
                          label="Show archived"
                          onChange={() => {
                            this.setState({
                              showArchivedUsers: !this.state.showArchivedUsers,
                            });
                            this.ticketMap();
                          }}
                        />
                      </Form.Group>
                      <Button
                        className="circular-btn plus-btn"
                        onClick={() => this.onModalOpen('addTicket')}
                      >
                        <FontAwesomeIcon
                          className="circular-btn-icon fa-fw"
                          icon={faPlus}
                        />
                      </Button>
                      <Button
                        className="refresh-btn circular-btn"
                        onClick={() => this.onFetchJotForms()}
                      >
                        <FontAwesomeIcon
                          className="circular-btn-icon fa-fw"
                          icon={faSyncAlt}
                        />
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <ListGroup className="tickets-row-title-group">
                <ListGroup.Item className="tickets-row-title">
                  <span className="tickets-row-item">
                    Name{' '}
                    <div className="sorting-buttons">
                      <Button onClick={() => this.onSortBy('displayName')}>
                        <FontAwesomeIcon
                          className={
                            (this.state.sortedBy === 'displayNameUp'
                              ? 'sorted-by'
                              : ' ') + ' fa-fw'
                          }
                          icon={faCaretUp}
                        />
                        <FontAwesomeIcon
                          className={
                            (this.state.sortedBy === 'displayNameDown'
                              ? 'sorted-by'
                              : ' ') + ' fa-fw'
                          }
                          icon={faCaretDown}
                        />
                      </Button>
                    </div>
                  </span>
                  <span className="tickets-row-item hide-if-small">
                    Email{' '}
                    <div className="sorting-buttons">
                      <Button onClick={() => this.onSortBy('email')}>
                        <FontAwesomeIcon
                          className={
                            (this.state.sortedBy === 'emailUp'
                              ? 'sorted-by'
                              : ' ') + ' fa-fw'
                          }
                          icon={faCaretUp}
                        />
                        <FontAwesomeIcon
                          className={
                            (this.state.sortedBy === 'emailDown'
                              ? 'sorted-by'
                              : ' ') + ' fa-fw'
                          }
                          icon={faCaretDown}
                        />
                      </Button>
                    </div>
                  </span>
                  <span className="tickets-row-item text-center">
                    Tickets{' '}
                    <div className="sorting-buttons">
                      <Button onClick={() => this.onSortBy('remainingUses')}>
                        <FontAwesomeIcon
                          className={
                            (this.state.sortedBy === 'remainingUsesUp'
                              ? 'sorted-by'
                              : ' ') + ' fa-fw'
                          }
                          icon={faCaretUp}
                        />
                        <FontAwesomeIcon
                          className={
                            (this.state.sortedBy === 'remainingUsesDown'
                              ? 'sorted-by'
                              : ' ') + ' fa-fw'
                          }
                          icon={faCaretDown}
                        />
                      </Button>
                    </div>
                  </span>
                </ListGroup.Item>
              </ListGroup>
              <Accordion>{this.ticketMap(permissions)}</Accordion>
            </Col>
          </Row>
        </Container>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.modalAction === 'addTicket'
                ? `Add a FlexPass`
                : 'Archive User'}
            </Modal.Title>
          </Modal.Header>
          {this.state.modalAction === 'addTicket' && (
            <Modal.Body>
              <Form.Group>
                <Form.Label htmlFor="firstName">First Name</Form.Label>
                <Form.Control
                  id="firstName"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  autoComplete="given-name"
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  required={true}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={
                    this.state.error_firstName && this.state.focused_firstName
                      ? ''
                      : 'visible'
                  }
                >
                  {this.state.error_firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="lastName">Last Name</Form.Label>

                <Form.Control
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  autoComplete="family-name"
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  required={true}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={
                    this.state.error_lastName && this.state.focused_lastName
                      ? ''
                      : 'visible'
                  }
                >
                  {this.state.error_lastName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="email">Email</Form.Label>
                <Form.Control
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  autoComplete="name"
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={
                    this.state.error_email && this.state.focused_email
                      ? ''
                      : 'visible'
                  }
                >
                  {this.state.error_email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Number of Tickets</Form.Label>
                <Form.Control
                  id="numberOfTickets"
                  type="number"
                  name="numberOfTickets"
                  max={48}
                  min={0}
                  defaultValue={12}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                ></Form.Control>
                <Form.Control.Feedback
                  type="invalid"
                  className={
                    this.state.error_numberOfTickets &&
                    this.state.focused_numberOfTickets
                      ? ''
                      : 'visible'
                  }
                >
                  {this.state.error_numberOfTickets}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              variant={
                this.state.modalAction === 'addTicket' ? 'primary' : 'danger'
              }
              onClick={this.onHandleModalSubmit}
            >
              {this.state.modalAction === 'addTicket'
                ? 'Add FlexPass'
                : 'Archive User'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

Tickets.propTypes = {
  from: PropTypes.string.isRequired,
  permissions: PropTypes.string.isRequired,
  errorMessagePassedOn: PropTypes.string,
  successMessagePassedOn: PropTypes.string,
  rerouteWithComponentLink: PropTypes.func.isRequired,
  updateFlexPassDB: PropTypes.func.isRequired,
  onAddTicketManuallySubmit: PropTypes.func.isRequired,
  clearAlertMessages: PropTypes.func.isRequired,
  updateTicketHistory: PropTypes.func.isRequired,
  removeHistoryItem: PropTypes.func.isRequired,
  updateRemainingTickets: PropTypes.func.isRequired,
  shows: PropTypes.arrayOf(PropTypes.object),
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.object,
      datePurchased: PropTypes.string,
      expiryDate: PropTypes.string,
      remainingUses: PropTypes.number,
      previousUses: PropTypes.arrayOf(
        PropTypes.shape({
          dateUsed: PropTypes.string,
          show: PropTypes.string,
          updatedBy: PropTypes.string,
        })
      ),
    })
  ),
};
