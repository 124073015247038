import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { isEmptyObject, redirectMap } from '../../helpers/helperFunctions';

function isLoggedInOnlyRoute(routePermissions) {
  return (
    routePermissions.includes('admin') ||
    routePermissions.includes('box-office') ||
    routePermissions.includes('personal')
  );
}

function PermissionsRoute({ component: Component, ...rest }) {
  let from = rest.from ? rest.from : '/';
  let user =
    isLoggedInOnlyRoute(rest.routePermissions) &&
    rest.permissions !== 'ticket-only' &&
    !isEmptyObject(rest.user)
      ? rest.user
      : {};
  let invalidUserAuthRedirectPathname = redirectMap(rest.permissions);

  if (rest.location.errorMessagePassedOn) {
    rest.errorMessagePassedOn = rest.location.errorMessagePassedOn;
  }
  return (
    <Route
      {...rest}
      render={() =>
        rest.routePermissions.includes(rest.permissions) ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: invalidUserAuthRedirectPathname,
              state: { from, permissions: rest.permissions, user },
            }}
          />
        )
      }
    />
  );
}

export default PermissionsRoute;

PermissionsRoute.propTypes = {
  component: PropTypes.any,
};
