import axios from 'axios';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavItem from 'react-bootstrap/NavItem';
import Spinner from 'react-bootstrap/Spinner';
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { isEmptyObject, isStatusOk } from '../../helpers/helperFunctions';
import PermissionsRoute from '../AuthedRoutes/PermissionsRoute';
import Dashboard from '../Dashboard/Dashboard';
import Forgot from '../Forgot/Forgot';
import Login from '../Login/Login';
import Register from '../Register/Register';
import Reset from '../Reset/Reset';
import Tickets from '../Tickets/Tickets';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      permissions: 'ticket-only',
      isLoading: true,
      isSoftLoading: false,
      from: '/',
      errorMessagePassedOn: '',
      successMessagePassedOn: '',
      changeState: false,
      tickets: [],
    };
    this.authFunc = this.authFunc.bind(this);
    this.logout = this.logout.bind(this);
    this.onForgotFormSubmit = this.onForgotFormSubmit.bind(this);
    this.onLoginFormSubmit = this.onLoginFormSubmit.bind(this);
    this.onRegisterFormSubmit = this.onRegisterFormSubmit.bind(this);
    this.onResetFormSubmit = this.onResetFormSubmit.bind(this);
    this.updateFlexPassDB = this.updateFlexPassDB.bind(this);
    this.clearAlertMessages = this.clearAlertMessages.bind(this);
    this.fetchAllTickets = this.fetchAllTickets.bind(this);
    this.fetchAllUsers = this.fetchAllUsers.bind(this);
    this.onUpdateUserFormSubmit = this.onUpdateUserFormSubmit.bind(this);
    this.rerouteWithComponentLink = this.rerouteWithComponentLink.bind(this);
    this.updateRemainingTickets = this.updateRemainingTickets.bind(this);
    this.updateSchedule = this.updateSchedule.bind(this);
    this.fetchSchedule = this.fetchSchedule.bind(this);
    this.removeHistoryItem = this.removeHistoryItem.bind(this);
    this.onAddTicketManuallySubmit = this.onAddTicketManuallySubmit.bind(this);
    this.isUserAdmin = this.isUserAdmin.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.isUserBoxOffice = this.isUserBoxOffice.bind(this);
    this.filterTicketsForPersonal = this.filterTicketsForPersonal.bind(this);
    this.isUserLoggedIn = this.isUserLoggedIn.bind(this);
    this.sortBy = this.sortBy.bind(this);
    this.sortDates = this.sortDates.bind(this);
    this.sortByOpeningDates = this.sortByOpeningDates.bind(this);
    this.whatToRefresh = this.whatToRefresh.bind(this);
    this.updateTicketHistory = this.updateTicketHistory.bind(this);
  }

  isUserAdmin(permissions) {
    return permissions === 'admin';
  }

  isUserBoxOffice(permissions) {
    return permissions === 'admin' || permissions === 'box-office';
  }

  isUserLoggedIn(permissions) {
    return permissions !== 'ticket-only';
  }

  whatToRefresh(permissions) {
    this.fetchAllTickets('displayName');
    if (this.isUserBoxOffice(permissions)) {
      this.fetchAllUsers();
      this.fetchSchedule();
    }
  }

  async authFunc() {
    this.setState({ isLoading: true });
    axios.get('/api/authState').then((res) => {
      try {
        if (isStatusOk(res.status) && !isEmptyObject(res.data.user)) {
          this.setState({
            user: res.data.user,
            permissions: res.data.user.permissions,
            isLoading: false,
          });
          this.whatToRefresh(res.data.user.permissions);
        } else if (res.status !== 304) {
          this.setState({
            user: {},
            permissions: 'ticket-only',
            isLoading: false,
          });
        }
      } catch (err) {
        this.setState({
          user: {},
          permissions: 'ticket-only',
          isLoading: false,
        });
        console.log(err);
      }
    });
  }

  async componentDidMount() {
    this.authFunc();
  }

  clearAlertMessages() {
    this.setState({ successMessagePassedOn: '', errorMessagePassedOn: '' });
  }

  logout() {
    axios
      .get('/api/auth/logout', { withCredentials: true })
      .then(() => {
        this.setState({
          user: {},
          permissions: 'ticket-only',
          isLoading: false,
          isSoftLoading: false,
          from: '/',
          errorMessagePassedOn: '',
          successMessagePassedOn: '',
          changeState: false,
          tickets: [],
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          user: {},
          permissions: 'ticket-only',
          isLoading: false,
          isSoftLoading: false,
          from: '/',
          errorMessagePassedOn: '',
          successMessagePassedOn: '',
          changeState: false,
          tickets: [],
        });
      });
  }

  onLoginFormSubmit(email, password) {
    this.setState({ isLoading: true });
    axios
      .post('/api/users/login', {
        email: email,
        password: password,
      })
      .then((res) => {
        try {
          if (isStatusOk(res.status)) {
            if (!isEmptyObject(res.data.user)) {
              this.setState({
                user: res.data.user,
                permissions: res.data.user.permissions,
                successMessagePassedOn: '',
                errorMessagePassedOn: '',
                isLoading: false,
              });
              this.whatToRefresh(res.data.user.permissions);
            } else {
              this.setState({
                errorMessagePassedOn: res.data.errorMessage,
                user: {},
                permissions: 'ticket-only',
                isLoading: false,
              });
            }
          }
        } catch (err) {
          this.setState({
            user: {},
            permissions: 'ticket-only',
            isLoading: false,
          });
          console.log(err);
        }
      });
  }

  onRegisterFormSubmit(firstName, lastName, email, password, password2) {
    this.setState({ isLoading: true });
    axios
      .post('/api/users/register', {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        password2: password2,
      })
      .then((res) => {
        try {
          if (isStatusOk(res.status) && res.data.successMessage) {
            this.setState(
              {
                user: {},
                permissions: 'ticket-only',
                isLoading: false,
                successMessagePassedOn: res.data.successMessage,
              },
              function () {
                this.rerouteWithComponentLink('/login', '/register');
              }
            );
          } else if (isStatusOk(res.status) && res.data.errorMessage) {
            this.setState({
              permissions: 'ticket-only',
              isLoading: false,
              errorMessagePassedOn: res.data.errorMessage,
            });
          }
        } catch (err) {
          this.setState(
            {
              user: {},
              permissions: 'ticket-only',
              isLoading: false,
              errorMessagePassedOn: 'Sorry, something went wrong.',
            },
            function () {
              this.rerouteWithComponentLink('/login', '/register');
            }
          );
          console.log(err);
        }
      });
  }

  onForgotFormSubmit(email) {
    this.setState({ isLoading: true });
    axios
      .post('/api/users/forgot', {
        email: email,
      })
      .then((res) => {
        try {
          if (isStatusOk(res.status)) {
            this.setState(
              {
                user: {},
                permissions: 'ticket-only',
                isLoading: false,
                successMessagePassedOn: res.data.successMessage,
              },
              function () {
                this.rerouteWithComponentLink('/login', '/forgot');
              }
            );
          }
        } catch (err) {
          console.log(err);
          this.setState(
            {
              user: {},
              permissions: 'ticket-only',
              isLoading: false,
              errorMessagePassedOn: res.data.errorMessage,
            },
            function () {
              this.rerouteWithComponentLink('/login', '/forgot');
            }
          );
        }
      });
  }

  onResetFormSubmit(password, password2, id) {
    this.setState({ isLoading: true });
    axios
      .post('/api/users/reset/' + id, {
        password: password,
        password2: password2,
      })
      .then((res) => {
        try {
          if (isStatusOk(res.status) && res.data.successMessage) {
            this.setState(
              {
                user: {},
                permissions: 'ticket-only',
                isLoading: false,
                successMessagePassedOn: res.data.successMessage,
              },
              function () {
                this.rerouteWithComponentLink('/login', '/reset/' + id);
              }
            );
          } else if (isStatusOk(res.status) && res.data.errorMessage) {
            this.setState(
              {
                user: {},
                permissions: 'ticket-only',
                isLoading: false,
                errorMessagePassedOn: res.data.errorMessage,
              },
              function () {
                this.rerouteWithComponentLink('/login', '/reset' + id);
              }
            );
          }
        } catch (err) {
          this.setState(
            {
              user: {},
              permissions: 'ticket-only',
              isLoading: false,
              errorMessagePassedOn: 'Sorry, something went wrong.',
            },
            function () {
              this.rerouteWithComponentLink('/login', '/reset' + id);
            }
          );
          console.log(err);
        }
      });
  }

  rerouteWithComponentLink(setTo, setFrom, optionalErrorMessage = '') {
    this.setState(
      { from: setFrom + '#', errorMessagePassedOn: optionalErrorMessage },
      () => {
        this.props.history.push(setTo);
      }
    );
  }

  updateFlexPassDB() {
    if (this.isUserBoxOffice(this.state.permissions)) {
      this.setState({ isSoftLoading: true });
      axios.get('/api/jotforms').then((res) => {
        if (isStatusOk(res.status) && res.data.successMessage) {
          this.setState({
            successMessagePassedOn: res.data.successMessage,
          });
          this.fetchAllTickets('displayName');
        }
        if (isStatusOk(res.status) && res.data.errorMessage) {
          this.setState({
            isSoftLoading: false,
            errorMessagePassedOn: res.data.errorMessage,
          });
        }
      });
    }
  }

  sortBy(category, reverse, isTickets = false) {
    if (category === 'displayName') {
      return function (a, b) {
        if (a.permissions === 'archived' && b.permissions !== 'archived') {
          return 1;
        }
        if (a.permissions !== 'archived' && b.permissions === 'archived') {
          return -1;
        } else if (
          isTickets &&
          a.user[category].split(' ')[a.user[category].split(' ').length - 1] >
            b.user[category].split(' ')[b.user[category].split(' ').length - 1]
        ) {
          return reverse ? -1 : 1;
        } else if (
          isTickets &&
          a.user[category].split(' ')[a.user[category].split(' ').length - 1] <
            b.user[category].split(' ')[b.user[category].split(' ').length - 1]
        ) {
          return reverse ? 1 : -1;
        } else if (
          !isTickets &&
          a[category].split(' ')[a[category].split(' ').length - 1] >
            b[category].split(' ')[b[category].split(' ').length - 1]
        ) {
          return reverse ? -1 : 1;
        } else if (
          !isTickets &&
          a[category].split(' ')[a[category].split(' ').length - 1] <
            b[category].split(' ')[b[category].split(' ').length - 1]
        ) {
          return reverse ? 1 : -1;
        }
        return 0;
      };
    } else if (category !== 'remainingUses' && isTickets) {
      return function (a, b) {
        if (a.remainingUses === 0 && b.remainingUses !== 0) {
          return 1;
        }
        if (a.remainingUses !== 0 && b.remainingUses === 0) {
          return -1;
        }
        if (a.user[category] > b.user[category]) {
          return reverse ? -1 : 1;
        } else if (a.user[category] < b.user[category]) {
          return reverse ? 1 : -1;
        }
        return 0;
      };
    } else {
      return function (a, b) {
        if (a.permissions === 'archived' && b.permissions !== 'archived') {
          return 1;
        }
        if (a.permissions !== 'archived' && b.permissions === 'archived') {
          return -1;
        }
        if (isTickets) {
          if (a.remainingUses === 0 && b.remainingUses !== 0) {
            return 1;
          }
          if (a.remainingUses !== 0 && b.remainingUses === 0) {
            return -1;
          }
        }
        if (a[category] > b[category]) {
          return reverse ? -1 : 1;
        } else if (a[category] < b[category]) {
          return reverse ? 1 : -1;
        }
        return 0;
      };
    }
  }

  sortByOpeningDates() {
    return function (a, b) {
      if (a.dates[0].date > b.dates[0].date) {
        return 1;
      } else if (a.dates[0].date < b.dates[0].date) {
        return -1;
      }
      return 0;
    };
  }

  filterTicketsForPersonal(tickets, sortedBy) {
    try {
      if (this.isUserBoxOffice(this.state.permissions)) {
        return tickets.sort(
          this.sortBy(
            sortedBy.replace('Down', '').replace('Up', ''),
            sortedBy.includes('Up'),
            true
          )
        );
      } else if (this.isUserLoggedIn(this.state.permissions)) {
        return tickets.filter((t) => {
          return t.user._id === this.state.user._id;
        });
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  fetchAllTickets(sortedBy) {
    this.setState({ isSoftLoading: true });
    axios.get('/api/flexpass').then((res) => {
      if (isStatusOk(res.status) && !res.data.errorMessage) {
        this.setState({
          isSoftLoading: false,
          tickets: this.filterTicketsForPersonal(res.data.flexPass, sortedBy),
        });
      }
      if (isStatusOk(res.status) && res.data.errorMessage) {
        this.setState({
          isSoftLoading: false,
          errorMessagePassedOn: res.data.errorMessage,
        });
      } else {
        this.setState({
          isSoftLoading: false,
        });
      }
    });
  }

  fetchAllUsers() {
    if (this.isUserAdmin(this.state.permissions)) {
      this.setState({ isSoftLoading: true });

      axios.get('/api/users').then((res) => {
        if (isStatusOk(res.status) && !res.data.errorMessage) {
          this.setState({
            isSoftLoading: false,
            users: res.data.users.sort(this.sortBy('displayName', false)),
          });
        }
        if (isStatusOk(res.status) && res.data.errorMessage) {
          this.setState({
            isSoftLoading: false,
            errorMessagePassedOn: res.data.errorMessage,
          });
        }
      });
    }
  }

  updateRemainingTickets(userToUpdate, showName, howToUpdate, sortedBy) {
    if (this.isUserBoxOffice(this.state.permissions)) {
      this.setState({ isSoftLoading: true });

      axios
        .put('/api/flexpass/user/' + userToUpdate._id, {
          user: userToUpdate,
          showName: showName,
          howToUpdate,
        })
        .then((res) => {
          if (isStatusOk(res.status) && !res.data.errorMessage) {
            this.fetchAllTickets(sortedBy);
          }
          if (isStatusOk(res.status) && res.data.errorMessage) {
            this.setState({
              isSoftLoading: false,
              errorMessagePassedOn: res.data.errorMessage,
            });
          }
        });
    }
  }

  updateTicketHistory(userId, ticket, sortedBy) {
    if (this.isUserBoxOffice(this.state.permissions)) {
      this.setState({ isSoftLoading: true });
      axios
        .put('/api/flexpass/user/ticketHistory/' + userId, {
          ticket,
        })
        .then((res) => {
          if (isStatusOk(res.status) && !res.data.errorMessage) {
            this.fetchAllTickets(sortedBy);
          }
          if (isStatusOk(res.status) && res.data.errorMessage) {
            this.setState({
              isSoftLoading: false,
              errorMessagePassedOn: res.data.errorMessage,
            });
          }
        });
    }
  }

  removeHistoryItem(ticketId, historyItemId, sortedBy) {
    if (this.isUserBoxOffice(this.state.permissions)) {
      this.setState({ isSoftLoading: true });

      axios
        .put('/api/flexpass/ticket/' + ticketId, {
          historyItemId,
        })
        .then((res) => {
          if (isStatusOk(res.status) && !res.data.errorMessage) {
            this.fetchAllTickets(sortedBy);
          }
          if (isStatusOk(res.status) && res.data.errorMessage) {
            this.setState({
              isSoftLoading: false,
              errorMessagePassedOn: res.data.errorMessage,
            });
          }
        });
    }
  }

  onUpdateUserFormSubmit(id, userFieldsToUpdate) {
    if (this.isUserAdmin(this.state.permissions)) {
      this.setState({ isSoftLoading: true });
      axios.put('/api/users/edit/' + id, userFieldsToUpdate).then((res) => {
        if (isStatusOk(res.status) && !res.data.errorMessage) {
          this.setState({
            isSoftLoading: false,
          });
          this.fetchAllUsers();
        }
        if (isStatusOk(res.status) && res.data.errorMessage) {
          this.setState({
            isSoftLoading: false,
            errorMessagePassedOn: res.data.errorMessage,
          });
        }
      });
    }
  }

  updateSchedule(savedShows) {
    if (this.isUserAdmin(this.state.permissions)) {
      this.setState({ isSoftLoading: true });
      axios.post('/api/shows/', savedShows).then((res) => {
        if (isStatusOk(res.status) && !res.data.errorMessage) {
          this.setState({
            isSoftLoading: false,
          });
          this.fetchSchedule();
        }
        if (isStatusOk(res.status) && res.data.errorMessage) {
          this.setState({
            isSoftLoading: false,
            errorMessagePassedOn: res.data.errorMessage,
          });
        }
      });
    }
  }

  deleteUser(userId) {
    if (this.isUserAdmin(this.state.permissions)) {
      this.setState({ isSoftLoading: true });
      axios.delete('/api/users/' + userId).then((res) => {
        if (isStatusOk(res.status) && !res.data.errorMessage) {
          this.setState({
            isSoftLoading: false,
          });
          this.fetchAllUsers();
          this.fetchAllTickets();
        }
        if (isStatusOk(res.status) && res.data.errorMessage) {
          this.setState({
            isSoftLoading: false,
            errorMessagePassedOn: res.data.errorMessage,
          });
        }
      });
    }
  }

  sortDates() {
    return function (a, b) {
      if (a.date > b.date) {
        return 1;
      } else if (a.date < b.date) {
        return -1;
      }
      return 0;
    };
  }

  fetchSchedule() {
    if (this.isUserAdmin(this.state.permissions)) {
      this.setState({ isSoftLoading: true });

      axios.get('/api/shows').then((res) => {
        if (isStatusOk(res.status) && !res.data.errorMessage) {
          this.setState({
            isSoftLoading: false,
            shows: res.data.shows.sort(this.sortByOpeningDates()),
          });
        }
        if (isStatusOk(res.status) && res.data.errorMessage) {
          this.setState({
            isSoftLoading: false,
            errorMessagePassedOn: res.data.errorMessage,
          });
        }
      });
    }
  }

  onAddTicketManuallySubmit(ticketInfo) {
    this.setState({ isSoftLoading: true });
    axios.post('/api/flexpass/add/', ticketInfo).then((res) => {
      try {
        if (isStatusOk(res.status) && res.data.successMessage) {
          this.setState(
            {
              isSoftLoading: false,
              successMessagePassedOn: res.data.successMessage,
            },
            this.whatToRefresh(this.state.permissions)
          );
        } else if (isStatusOk(res.status) && res.data.errorMessage) {
          this.setState(
            {
              isSoftLoading: false,
              errorMessagePassedOn: res.data.errorMessage,
            },
            this.whatToRefresh(this.state.permissions)
          );
        }
      } catch (err) {
        this.setState({
          isSoftLoading: false,
          errorMessagePassedOn: 'Sorry, something went wrong.',
        });
        console.log(err);
      }
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    }

    return (
      <div>
        {this.state.isSoftLoading && (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
        <div>
          {this.isUserLoggedIn(this.state.permissions) && (
            <Navbar expand="lg" bg="dark" variant="dark">
              <Navbar.Brand
                as={Link}
                to={
                  this.isUserLoggedIn(this.state.permissions) ? '/' : '/login'
                }
              >
                YTN Tickets
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbar-menu" />
              <Navbar.Collapse id="navbar-menu">
                <Nav className="mr-auto">
                  {this.isUserAdmin(this.state.permissions) && (
                    <NavItem eventkey={1} href="/">
                      <Nav.Link
                        as={Link}
                        onClick={() => {
                          this.setState({
                            from: '/',
                            successMessagePassedOn: '',
                            errorMessagePassedOn: '',
                          });
                        }}
                        to="/"
                      >
                        Dashboard
                      </Nav.Link>
                    </NavItem>
                  )}
                  {this.isUserLoggedIn(this.state.permissions) && (
                    <NavItem eventkey={5} href="/tickets">
                      <Nav.Link
                        as={Link}
                        onClick={() => {
                          this.setState({
                            from: '/tickets',
                            successMessagePassedOn: '',
                            errorMessagePassedOn: '',
                          });
                        }}
                        to="/tickets"
                      >
                        Tickets
                      </Nav.Link>
                    </NavItem>
                  )}
                </Nav>
                <Nav>
                  <NavItem eventkey={5} className="purchase-flexpass-item">
                    <a
                      href="https://form.jotform.com/203266852391155"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link purchase-flexpass"
                    >
                      Buy FlexPass
                    </a>
                  </NavItem>
                  <NavItem eventkey={6} className="">
                    <Nav.Link
                      as={Link}
                      onClick={() => {
                        this.setState({
                          from: '/forgot',
                          successMessagePassedOn: '',
                          errorMessagePassedOn: '',
                        });
                      }}
                      to="/forgot"
                    >
                      Reset Password
                    </Nav.Link>
                  </NavItem>
                  <NavItem eventkey={6} className="">
                    <Nav.Link
                      as={Link}
                      onClick={() => {
                        this.logout();
                      }}
                      to="/"
                    >
                      Logout
                    </Nav.Link>
                  </NavItem>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          )}
        </div>
        <div>
          <Switch>
            <PermissionsRoute
              exact
              path="/"
              permissions={this.state.permissions}
              component={Dashboard}
              from={this.state.from}
              user={this.state.user}
              users={this.state.users}
              shows={this.state.shows}
              tickets={this.state.tickets}
              onUpdateUserFormSubmit={this.onUpdateUserFormSubmit}
              rerouteWithComponentLink={this.rerouteWithComponentLink}
              errorMessagePassedOn={this.state.errorMessagePassedOn}
              successMessagePassedOn={this.state.successMessagePassedOn}
              clearAlertMessages={this.clearAlertMessages}
              updateSchedule={this.updateSchedule}
              deleteUser={this.deleteUser}
              fetchSchedule={this.fetchSchedule}
              routePermissions={['admin']}
            />
            <PermissionsRoute
              exact
              path="/forgot"
              permissions={this.state.permissions}
              component={Forgot}
              from={this.state.from}
              user={this.state.user}
              onForgotFormSubmit={this.onForgotFormSubmit}
              rerouteWithComponentLink={this.rerouteWithComponentLink}
              errorMessagePassedOn={this.state.errorMessagePassedOn}
              clearAlertMessages={this.clearAlertMessages}
              successMessagePassedOn={this.state.successMessagePassedOn}
              routePermissions={[
                'ticket-only',
                'admin',
                'box-office',
                'personal',
              ]}
            ></PermissionsRoute>
            <PermissionsRoute
              exact
              path="/login"
              permissions={this.state.permissions}
              component={Login}
              from={this.state.from}
              user={this.state.user}
              onLoginFormSubmit={this.onLoginFormSubmit}
              rerouteWithComponentLink={this.rerouteWithComponentLink}
              errorMessagePassedOn={this.state.errorMessagePassedOn}
              clearAlertMessages={this.clearAlertMessages}
              successMessagePassedOn={this.state.successMessagePassedOn}
              routePermissions={['ticket-only']}
            ></PermissionsRoute>
            <PermissionsRoute
              exact
              path="/register"
              permissions={this.state.permissions}
              component={Register}
              from={this.state.from}
              user={this.state.user}
              onRegisterFormSubmit={this.onRegisterFormSubmit}
              rerouteWithComponentLink={this.rerouteWithComponentLink}
              errorMessagePassedOn={this.state.errorMessagePassedOn}
              clearAlertMessages={this.clearAlertMessages}
              successMessagePassedOn={this.state.successMessagePassedOn}
              routePermissions={['ticket-only']}
            ></PermissionsRoute>
            <PermissionsRoute
              exact
              path="/reset/:id"
              permissions={this.state.permissions}
              component={Reset}
              from={this.state.from}
              user={this.state.user}
              onResetFormSubmit={this.onResetFormSubmit}
              rerouteWithComponentLink={this.rerouteWithComponentLink}
              clearAlertMessages={this.clearAlertMessages}
              errorMessagePassedOn={this.state.errorMessagePassedOn}
              successMessagePassedOn={this.state.successMessagePassedOn}
              routePermissions={[
                'ticket-only',
                'admin',
                'box-office',
                'personal',
              ]}
            ></PermissionsRoute>
            <PermissionsRoute
              exact
              path="/tickets"
              permissions={this.state.permissions}
              component={Tickets}
              from={this.state.from}
              user={this.state.user}
              tickets={this.state.tickets}
              rerouteWithComponentLink={this.rerouteWithComponentLink}
              routePermissions={['admin', 'box-office', 'personal']}
              clearAlertMessages={this.clearAlertMessages}
              updateFlexPassDB={this.updateFlexPassDB}
              updateRemainingTickets={this.updateRemainingTickets}
              removeHistoryItem={this.removeHistoryItem}
              errorMessagePassedOn={this.state.errorMessagePassedOn}
              successMessagePassedOn={this.state.successMessagePassedOn}
              onAddTicketManuallySubmit={this.onAddTicketManuallySubmit}
              updateTicketHistory={this.updateTicketHistory}
              shows={this.state.shows}
            />
            <Route
              render={function () {
                <Redirect to={'/tickets'} />;
              }}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(Navigation);

Navigation.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
